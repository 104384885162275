<template>
  <div>
    <van-empty v-if="!SXXBJ" description="报警已删除" />
    <el-card v-else class="card" :body-style="{ padding: '5px' }">
      <van-cell-group inset style="margin:0 0 5px 0">
        <van-collapse v-model="activeNames">
          <van-collapse-item title="传感器" name="1">
            {{ SXXBJ.sensorName }}</van-collapse-item>
        </van-collapse>
        <van-cell title="传感器时间">{{ SXXBJ.dataTime }}</van-cell>
        <van-cell title="传感器温度(℃)" title-class="font" value-class="fontsize">{{ SXXBJ.temperature }}</van-cell>
        <van-cell title="上限值(℃)" title-class="font" value-class="fontsize">{{ wxcW81_BJ_SXXBJ.shangXian }}</van-cell>
        <van-cell title="上限值(℃)" title-class="font" value-class="fontsize">{{ wxcW81_BJ_SXXBJ.xiaXian }}</van-cell>
      </van-cell-group>
      <el-row style="margin-top: 5px;">
        <van-cell title="报警时间">{{ SXXBJ.createTime }}</van-cell>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/css/digital.css";
Vue.use(Vant);
export default {
  props: ["id"],
  data() {
    return {
      SXXBJ: null,
      wxcW81_BJ_SXXBJ: null,
      activeNames: ["1", "2", "3"],
    };
  },
  methods: {
    getSXXBJ() {
      let that = this;
      that.axios
        .post("WXCW81/SXXBJ_Record_GetOne", {
          onlyInt: that.id,
        })
        .then((response) => {
          if (response.data.data !== null) {
            that.SXXBJ = response.data.data;
            that.wxcW81_BJ_SXXBJ = response.data.data.wxcW81_BJ_SXXBJ;
          }
        });
    },
  },
  mounted() {
    document.title = "上下限报警";
    let that = this;
    that.getSXXBJ();
  },
};
</script>

<style scoped>
.card {
  background-color: #409eff;
}
.font {
  font-size: 1em;
}
.fontsize {
  font-size: 1.2em;
  color: red;
}
</style>